<template>
  <main-card-header :title="routerTitle"></main-card-header>
  <div class="flex justify-center items-center content-evenly flex-wrap">
    <small-form
      title="Add Reason"
      instructions="Enter the new reason name"
      :inputs="formItems"
      @resolve-form-submit="addReason"
      :errorMessage="newReasonError"
    ></small-form>
    <div>
      <div class="flex justify-center flex-wrap">
        <div class="flex justify-center">
          <card-widget
            v-if="!isLoading"
            cardTitle="Reasons"
            icon="emoji_objects"
            :cardValue="getReasons.total"
          ></card-widget>
          <card-widget></card-widget>
        </div>
      </div>
      <div class="flex justify-center flex-wrap">
        <div class="flex justify-center">
          <card-widget></card-widget>
          <card-widget></card-widget>
        </div>
      </div>
    </div>
  </div>
  <template v-if="!isLoading">
    <dynamic-table
      v-if="!isLoading"
      title="Reasons"
      @delete="deleteReason"
    ></dynamic-table>
  </template>
  <template v-else>
    <heart-rate fast="true"></heart-rate>
  </template>
  <my-pagination
    v-if="!!getReasons.total"
    :options="options"
    v-model="page"
    :records="getReasons.total"
    :per-page="getReasons.per_page"
    @paginate="myCallback"
  >
  </my-pagination>
  <blank-paginate v-else />
</template>
<script>
import { markRaw } from "vue";
import customPaginate from "@/components/ui/baseComponents/paginate.vue";
import blankPaginate from "@/components/ui/baseComponents/blankPaginate.vue";
import dynamicTable from "@/components/layout/SA/table/table.vue";
import smallForm from "@/components/ui/forms/smallForm.vue";
import cardWidget from "@/components/ui/SA/cardWidget.vue";

export default {
  components: { dynamicTable, smallForm, cardWidget, blankPaginate },
  data() {
    return {
      options: {
        template: markRaw(customPaginate),
      },
      page: 1,
      tableHeaders: ["ID", "NAME", "CREATED", "ACTIONS"],
      formItems: [
        {
          type: "input",
          placeholder: "Reason",
          fieldName: "reason",
        },
      ],
      isLoading: false,
      newReasonError: null,
    };
  },
  provide() {
    return { headers: this.tableHeaders };
  },
  created() {
    this.loadReasons(this.page);
  },
  methods: {
    myCallback(e) {
      this.loadReasons(e);
    },
    // Load Users
    async loadReasons(page) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("admin/loadReasons", page);
        this.isLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    // Delete User
    async deleteReason(id) {
      try {
        await this.$store.dispatch("admin/deleteReason", id);
        this.isLoading = false;
        this.$toast.error(`Reason Deleted!`);
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    // Add User
    async addReason(data) {
      try {
        await this.$store.dispatch("admin/addReason", data);
        this.newReasonError = null;
        this.isLoading = false;
        this.$toast.success(`Reason Added!`);
      } catch (error) {
        this.error = error.message;
        this.newReasonError = error.message;
      }
    },
  },
  computed: {
    routerTitle() {
      return this.$route.meta.title;
    },
    getReasons() {
      return this.$store.getters["admin/getReasons"];
    },
  },
};
</script>
